import Company from './Company'
import { mapById } from './entities'
import Restaurant from './Restaurant'

export const areaConverter = {
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return new Area(snapshot.id, data)
  }
}

export default class Area {
  constructor (id, { companies, restaurants }) {
    /** @var {string} Name */
    this.name = id

    const companyMap = mapById(companies.map(c => new Company(c)))

    /** @var {Restaurant[]} */
    this.restaurants = restaurants.map(r => new Restaurant(r, companyMap))
  }
}
