import { useEffect, useState } from 'react'
import Masonry from '@mui/lab/Masonry'
import RestaurantCard from './RestaurantCard'
import { getRestaurantsSummary } from './firebase/db'

export default function RestaurantMasonry () {
  const [restaurants, setRestaurants] = useState([])

  useEffect(() => { getRestaurantsSummary().then(setRestaurants) }, [])

  return (
    <Masonry columns={{ xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }}>
      {restaurants.map((restaurant, index) => <RestaurantCard key={index} {...restaurant} />)}
    </Masonry>
  )
}
