// import Container from '@mui/material/Container'

import RestaurantMasonry from './RestaurantMasonry'

export default function App ({ children }) {
  return (
    <RestaurantMasonry />
    /* <Container maxWidth='xl'>
      {children}
    </Container> */
  )
}
