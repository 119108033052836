import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

export default function RestaurantCard ({ boycott, company, displayName, id, initials, google, name, nick }) {
  return (
    <Card>
      <CardHeader avatar={<Avatar>{initials}</Avatar>} title={displayName} subheader={company && company.nick} />
      {/* <CardContent><Typography style={{ textDecoration: boycott ? 'line-through' : 'none' }}>{google}</Typography>
      </CardContent>
       <CardActions>
        <Button size='small'>Learn More</Button>
  </CardActions> */}
    </Card>
  )
}
