import {
  doc,
  getDoc,
  getFirestore
} from 'firebase/firestore/lite'
import { areaConverter } from '../Area'
import app from './app'

const db = getFirestore(app)

/**
 * Get summary of restaurants
 * @returns {import('../Restaurant').default[]}
 */
export async function getRestaurantsSummary () {
  const ref = doc(db, 'areas', 'Mikkeli').withConverter(areaConverter)
  const snap = await getDoc(ref)
  return snap.exists() ? snap.data().restaurants : []
}
