import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
// import Base from './Base'
// import GoogleMap from './GoogleMap'

const root = createRoot(document.getElementById('root'))
root.render(<App />)
/* root.render(
  <Base>
    <App />
  </Base>
) */

{ /* <GoogleMap center={{ lat: -34.397, lng: 150.644 }} zoom={4} /> */ }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
