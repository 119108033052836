/**
 * Format business ID from integer value.
 * @param {number} no
 * @returns {string}
 */
function formatBusinessId (no) {
  if (!no) return null
  let str = no.toString()
  while (str.length < 8) str = '0' + str
  return `${str.substring(0, 7)}-${str.charAt(7)}`
}

export default class Company {
  constructor ({ a, i, n, y }) {
    /** @type {string} */
    this.businessId = formatBusinessId(y)

    /** @type {string} */
    this.id = i

    /** @type {string} */
    this.name = a

    /** @type {string} */
    this.nick = n
  }

  toString () {
    return this.businessId ? `${this.businessId} ${this.name}` : this.nick
  }
}
