import { initializeApp } from 'firebase/app'

export const apiKey = 'AIzaSyBKefoY6yvIJAUi8inauH2wYi8Lb2U28Cw'

const app = initializeApp({
  apiKey,
  authDomain: 'menu-fi.firebaseapp.com',
  projectId: 'menu-fi',
  storageBucket: 'menu-fi.appspot.com',
  messagingSenderId: '130136299276',
  appId: '1:130136299276:web:a9b28038db9ae9a4e35b29',
  measurementId: 'G-369ZZEWGRH'
})

export default app
