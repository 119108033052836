export default class Restaurant {
  constructor ({ a, b, g, i, l, n, s }, companyMap) {
    /** @type {boolean} */
    this.boycott = !!b

    const ids = i.split('_')

    /** @type {import('./Company').default|null} */
    this.company = ids.length < 2 ? null : companyMap[ids[0]]

    /** @type {string} */
    this.displayName = l

    /** @type {string} */
    this.google = g

    /** @type {string} */
    this.id = i

    /** @type {string} */
    this.initials = s

    /** @type {string} */
    this.name = a

    /** @type {string} */
    this.nick = n
  }
}
